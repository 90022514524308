import React from "react";

interface Props {
  style?: React.CSSProperties;
  primaryColor?: string;
  secondaryColor?: string;
}

export default function Logo({
  style,
  primaryColor = "#221714",
  secondaryColor = "#CDA83F",
}: Props) {
  return (
    <svg
      style={style}
      x="0px"
      y="0px"
      viewBox="0 270 595.28 841.89"
      focusable="false"
      role="presentation"
      height="100"
    >
      <g>
        <g>
          <g fill={primaryColor}>
            <path
              d="M85.57,575c-8.66,0-15.94-2.54-21.65-7.55c-5.61-5-9.13-12.05-10.5-20.97c-0.17-1.06,0.52-2.07,1.57-2.29
		l14.44-3.07c0.55-0.11,1.13,0.01,1.6,0.33c0.46,0.34,0.75,0.84,0.83,1.41c0.76,6.15,2.04,8.87,3,10.06
		c2.6,3.47,6.26,5.12,11.26,5.12c3.94,0,7.1-1.22,9.67-3.72c2.55-2.49,3.79-5.58,3.79-9.44c0-1.54-0.21-2.94-0.65-4.2
		c-0.42-1.22-1.1-2.36-2.01-3.39c-0.94-1.08-2.19-2.12-3.74-3.1c-1.61-1.01-3.56-2-5.81-2.93l-9.09-3.64
		c-13.72-5.58-20.67-13.97-20.67-24.95c0-7.42,2.97-13.7,8.85-18.67c5.77-4.92,13.02-7.43,21.56-7.43c11.6,0,20.76,5.46,27.24,16.24
		c0.28,0.46,0.36,1.03,0.23,1.56c-0.15,0.53-0.48,0.97-0.96,1.24l-11.56,6.6c-0.96,0.55-2.18,0.23-2.74-0.72
		c-2.46-4.09-4.31-5.71-5.43-6.34c-1.79-1.09-4.08-1.62-6.91-1.62c-3.49,0-6.3,0.9-8.56,2.78c-2.15,1.74-3.18,3.86-3.18,6.46
		c0,3.51,3.02,6.48,9,8.85l9.42,3.71c7.95,3.09,13.89,6.97,17.64,11.52c3.84,4.66,5.79,10.42,5.79,17.11
		c0,8.91-3.14,16.39-9.32,22.22C102.49,572.04,94.71,575,85.57,575L85.57,575z"
            />
            <path
              d="M375.28,549.26c-20.04,0-36.35-16.3-36.35-36.34c0-20.03,16.31-36.34,36.35-36.34
		c20.03,0,36.34,16.3,36.34,36.34C411.62,532.96,395.31,549.26,375.28,549.26L375.28,549.26z M375.28,493.49
		c-10.71,0-19.44,8.72-19.44,19.43c0,10.71,8.73,19.43,19.44,19.43c10.71,0,19.42-8.71,19.42-19.43
		C394.7,502.21,385.99,493.49,375.28,493.49L375.28,493.49z"
            />
            <path
              d="M237.04,549.27c-19.71,0-35.66-15.45-36.29-35.17c-0.64-20.03,15.12-36.85,35.15-37.49
		c20.21-0.67,36.84,15.03,37.49,35.15c0.02,0.46,0.02,0.92,0.01,1.38c0,0.05,0.01,0.11,0.01,0.17v33.49c0,1.12-0.91,2.02-2.02,2.02
		h-12.74c-1.12,0-2.02-0.9-2.02-2.02v-3.23c-5.49,3.51-11.77,5.46-18.38,5.68C237.84,549.26,237.44,549.27,237.04,549.27
		L237.04,549.27z M237.08,493.5l-0.64,0.01c-10.71,0.35-19.14,9.34-18.8,20.04c0.35,10.54,8.86,18.8,19.41,18.8l0.64-0.01
		c5.19-0.17,10-2.34,13.54-6.12c3.55-3.78,5.41-8.73,5.25-13.92C256.14,501.76,247.62,493.5,237.08,493.5L237.08,493.5z"
            />
            <path
              d="M157.17,549.26c-20.04,0-36.34-16.3-36.34-36.34c0-20.03,16.3-36.34,36.34-36.34
		c20.03,0,36.33,16.3,36.33,36.34c0,0.54-0.02,1.08-0.03,1.61c-0.04,1.09-0.94,1.94-2.02,1.94h-53.38
		c1.67,9.06,9.68,15.87,19.1,15.87c7.17,0,13.74-3.93,17.13-10.27c0.35-0.66,1.03-1.07,1.79-1.07h13.89c0.64,0,1.24,0.3,1.62,0.81
		c0.38,0.51,0.5,1.19,0.31,1.8C187.19,538.96,173.23,549.26,157.17,549.26L157.17,549.26z M139.19,502.86h35.96
		c-3.97-7.1-10.7-11.51-17.98-11.51C149.89,491.35,143.16,495.75,139.19,502.86L139.19,502.86z"
            />
            <path
              d="M481.69,546.69h-12.74c-1.12,0-2.02-0.9-2.02-2.03v-36.03c0-8.42-6.85-15.27-15.25-15.27
		c-8.42,0-15.26,6.85-15.26,15.27v36.03c0,1.12-0.91,2.03-2.02,2.03h-12.74c-1.12,0-2.02-0.9-2.02-2.03v-36.03
		c0-17.68,14.37-32.05,32.06-32.05c17.67,0,32.04,14.38,32.04,32.05v36.03C483.72,545.79,482.82,546.69,481.69,546.69L481.69,546.69
		z"
            />
            <path
              d="M306.75,549.27c-11.95,0-20.57-5.61-25.62-16.67c-0.16-0.32-0.16-0.69-0.05-1.02c0.14-0.33,0.39-0.6,0.71-0.74
		l13.13-5.84c0.18-0.08,0.36-0.11,0.54-0.11c0.46,0,0.89,0.24,1.13,0.64c3.07,5.11,3.78,5.73,4.02,5.93
		c2.1,1.87,4.26,2.82,6.41,2.82c6.59,0,7.58-4.36,7.58-6.95c0-3.02-3.52-5.36-6.17-6.85c-1.54-0.73-3.18-1.5-4.81-2.23
		c-1.63-0.73-3.28-1.49-4.96-2.27c-4.79-2.25-8.23-4.55-10.22-6.84c-2.59-2.97-3.91-6.82-3.91-11.44c0-6.07,2.21-11.17,6.57-15.15
		c4.42-3.95,9.85-5.96,16.14-5.96c9.18,0,16.13,4.54,20.65,13.48c0.33,0.65,0.07,1.45-0.58,1.78l-12.7,6.46
		c-0.2,0.1-0.43,0.16-0.65,0.16c-0.38,0-0.9-0.16-1.08-0.63c-1.43-3.66-2.79-7.11-7.78-7.11c-1.36,0-2.67,0.44-3.49,1.16
		c-0.92,0.84-1.49,1.98-1.52,3.08c-0.13,4.67,2.21,6.01,9.46,8.95l0.72,0.29c8.24,3.39,14.75,6.07,17.68,8.99
		c3.13,3.14,4.73,7.42,4.73,12.71c0,6.72-2.63,12.42-7.8,16.94C319.89,547.11,313.79,549.27,306.75,549.27L306.75,549.27z"
            />
            <path
              d="M516.82,549.27c-11.95,0-20.57-5.61-25.62-16.67c-0.15-0.32-0.15-0.69-0.03-1.02c0.13-0.33,0.39-0.6,0.7-0.74
		L505,525c0.18-0.08,0.35-0.11,0.54-0.11c0.45,0,0.89,0.24,1.13,0.64c3.07,5.11,3.79,5.73,4.02,5.93c2.1,1.87,4.26,2.82,6.41,2.82
		c6.59,0,7.58-4.36,7.58-6.95c0-3.02-3.53-5.36-6.17-6.85c-1.54-0.73-3.18-1.5-4.8-2.23c-1.63-0.73-3.28-1.49-4.96-2.27
		c-4.79-2.25-8.23-4.55-10.22-6.84c-2.59-2.97-3.9-6.82-3.9-11.44c0-6.07,2.2-11.17,6.56-15.15c4.42-3.95,9.85-5.96,16.14-5.96
		c9.18,0,16.14,4.54,20.65,13.48c0.32,0.65,0.06,1.45-0.58,1.78l-12.69,6.46c-0.2,0.1-0.43,0.16-0.66,0.16
		c-0.38,0-0.89-0.16-1.08-0.63c-1.43-3.66-2.78-7.11-7.78-7.11c-1.36,0-2.67,0.44-3.49,1.16c-0.93,0.84-1.5,1.98-1.53,3.08
		c-0.12,4.67,2.21,6.01,9.46,8.95l0.72,0.29c8.23,3.39,14.74,6.07,17.67,8.99c3.13,3.14,4.73,7.42,4.73,12.71
		c0,6.72-2.62,12.42-7.8,16.94C529.96,547.11,523.86,549.27,516.82,549.27L516.82,549.27z"
            />
            <path
              d="M379.79,467.27c-35.15,0-63.74-28.6-63.74-63.75v-71.67c0-2.22,1.8-4.02,4.02-4.02h25.34
			c2.24,0,4.02,1.8,4.02,4.02v71.67c0,16.74,13.62,30.36,30.36,30.36c16.74,0,30.36-13.61,30.36-30.36v-71.67
			c0-2.22,1.8-4.02,4.03-4.02h25.33c2.23,0,4.02,1.8,4.02,4.02v71.67C443.53,438.67,414.93,467.27,379.79,467.27L379.79,467.27z"
            />
            <path
              d="M497.1,462.74h-25.34c-2.22,0-4.03-1.8-4.03-4.01v-71.68c0.01-35.15,28.6-63.74,63.75-63.74
			c2.23,0,4.02,1.8,4.02,4.02v25.34c0,2.23-1.79,4.03-4.02,4.03c-16.73,0-30.36,13.61-30.36,30.35v71.68
			C501.12,460.95,499.32,462.74,497.1,462.74L497.1,462.74z"
            />
            <path
              d="M90,462.74H64.66c-2.22,0-4.02-1.8-4.02-4.02V330.74c0-35.15,28.6-63.74,63.74-63.74h25.6
			c2.22,0,4.02,1.8,4.02,4.03v25.33c0,2.22-1.8,4.02-4.02,4.02h-25.6c-15.75,0-28.74,12.07-30.22,27.44h46.18
			c2.23,0,4.02,1.8,4.02,4.02v25.35c0,2.22-1.8,4.02-4.02,4.02H94.01v97.51C94.01,460.94,92.23,462.74,90,462.74L90,462.74z"
            />
          </g>
          <g fill={secondaryColor}>
            <path
              d="M212.98,324.3c-30.63,4.91-54.85,28.85-59.99,59.36c-0.96,3.88,1.42,6.32,5.41,6.32h51.48
				c4.75,0,8.66-3.89,8.66-8.65v-51.37C218.53,326.04,216.35,323.76,212.98,324.3z"
            />
            <path
              d="M235.62,324.3c30.61,4.91,54.85,28.85,59.98,59.36c0.97,3.88-1.42,6.32-5.4,6.32h-51.49
				c-4.75,0-8.65-3.89-8.65-8.65v-51.37C230.06,326.04,232.23,323.76,235.62,324.3z"
            />
            <path
              d="M212.98,467.18c-30.63-4.91-54.85-28.83-59.99-59.37c-0.96-3.88,1.42-6.31,5.41-6.31h51.48
				c4.75,0,8.66,3.89,8.66,8.65v51.37C218.53,465.46,216.35,467.73,212.98,467.18z"
            />
            <path
              d="M235.62,467.18c30.61-4.91,54.85-28.83,59.98-59.37c0.97-3.88-1.42-6.31-5.4-6.31h-51.49
				c-4.75,0-8.65,3.89-8.65,8.65v51.37C230.06,465.46,232.23,467.73,235.62,467.18z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
