import { News } from "./state";

export const initData: News[] = [
  {
    _id: "5ee1f6b46160b9004b713852",
    id: "5ee1f6b46160b9004b713851",
    title: "京都工芸繊維大学の合同企業説明会に参加",
    category: "お知らせ",
    content:
      "<p>3月4日（月）10:00～13：10 京都工芸繊維大学<br>京都工芸繊維大学にて開催されるキャリアミーティング（合同企業説明会）に参加しました。</p>",
    display_date: "2019-04-22T03:53:00.000Z",
    created_at: "2020-06-11T09:17:40.096Z",
    updated_at: "2020-06-11T09:48:24.055Z",
  },
  {
    _id: "5ee1f7496160b9004b713854",
    id: "5ee1f7496160b9004b713853",
    title: "女の転職フェアに出展",
    category: "お知らせ",
    content:
      "<p>6月30日（土）渋谷ヒカリエ<br>女の転職フェアに出展しました。</p>",
    thumbnail:
      "https://officialwebstorage.blob.core.windows.net/images/6390326829924404-woman_20180524.png",
    display_date: "2019-06-29T15:00:00.000Z",
    created_at: "2020-06-11T09:20:09.743Z",
    updated_at: "2020-07-10T05:35:51.665Z",
  },
  {
    _id: "5ee1f77f6160b9004b713856",
    id: "5ee1f77f6160b9004b713855",
    title: " 女の転職フェアに出展",
    category: "お知らせ",
    content:
      "<p>2020年1月25日（土）渋谷ヒカリエ<br>女の転職フェアに出展しました。</p>",
    display_date: "2019-12-10T09:20:00.000Z",
    created_at: "2020-06-11T09:21:03.822Z",
    updated_at: "2020-06-11T09:51:15.060Z",
  },
  {
    _id: "5ee1f7976160b9004b713858",
    id: "5ee1f7976160b9004b713857",
    title: "2021年卒採用活動",
    category: "お知らせ",
    content:
      "<p>【2021年卒採用活動】<br>京都工芸繊維大学合同企業説明会「キャリアミーティング」参加<br>2020年3月2日(月)14:30～17:40<br>＠京都工芸繊維大学<br><br>「東商ジョブフェア」参加<br>2020年3月13日(金)13:00～17:00<br>＠サンシャインシティワールドインポートマートビル4F展示ホールA-2</p>",
    display_date: "2020-01-30T09:21:00.000Z",
    created_at: "2020-06-11T09:21:27.197Z",
    updated_at: "2020-06-11T09:51:20.755Z",
  },
  {
    _id: "5ee1f7d56160b9004b71385a",
    id: "5ee1f7d56160b9004b713859",
    title: "ホームページをリニューアルしました！",
    category: "お知らせ",
    content:
      "<p>ホームページをリニューアルしました！<br>今後ともフォーシーズンズをよろしくお願いいたします。</p>",
    display_date: "2020-05-22T09:22:00.000Z",
    created_at: "2020-06-11T09:22:29.453Z",
    updated_at: "2020-06-11T09:51:31.129Z",
  },
  {
    _id: "5ee1f7fa6160b9004b71385c",
    id: "5ee1f7fa6160b9004b71385b",
    title: "渋東シネタワーの合同説明会に参加",
    category: "お知らせ",
    content:
      "<p>3月6日（水）13：00～17：00 渋東シネタワー11階<br>「みつばち合説（合同説明会）」に参加しました。</p>",
    display_date: "2019-04-21T21:22:00.000Z",
    created_at: "2020-06-11T09:23:06.387Z",
    updated_at: "2020-06-11T09:50:22.127Z",
  },
  {
    _id: "5ee1f81b6160b9004b71385e",
    id: "5ee1f81b6160b9004b71385d",
    title: "新宿エルタワーの合同会社説明会に参加",
    category: "お知らせ",
    content:
      "<p>3月13日（水）13：00～17：00 新宿エルタワー30階<br>サンスカイルームで東商ジョブフェア（合同会社説明会）に参加しました。</p>",
    display_date: "2019-04-21T21:23:00.000Z",
    created_at: "2020-06-11T09:23:39.993Z",
    updated_at: "2020-06-11T09:50:32.735Z",
  },
  {
    _id: "5ee1f8636160b9004b713862",
    id: "5ee1f8636160b9004b713861",
    title: " 茨城大学で学内説明会を実施",
    category: "お知らせ",
    content: "<p>10月25日（木）茨城大学で学内説明会を実施しました。</p>",
    display_date: "2018-09-28T09:24:00.000Z",
    created_at: "2020-06-11T09:24:51.022Z",
    updated_at: "2020-06-11T09:49:49.918Z",
  },
  {
    _id: "5ee1f8856160b9004b713864",
    id: "5ee1f8856160b9004b713863",
    title: "女の転職フェアに出展",
    category: "お知らせ",
    content: "<p>4月21日（土）女の転職フェアに出展しました。</p>",
    display_date: "2018-03-12T09:24:00.000Z",
    created_at: "2020-06-11T09:25:25.719Z",
    updated_at: "2020-06-12T05:16:00.041Z",
  },
  {
    _id: "5ee1f8cf6160b9004b713866",
    id: "5ee1f8cf6160b9004b713865",
    title: "ISO27001（情報セキュリティ マネジメントシステム）取得",
    category: "お知らせ",
    content:
      '<p>ISO27001（情報セキュリティ マネジメントシステム）認証を取得しました。<br><a href="https://www.0004s.com/pdf/news/isms_registration_card.pdf#zoom=100">登録証はこちらからご覧いただけます。</a></p>',
    display_date: "2018-02-23T09:25:00.000Z",
    created_at: "2020-06-11T09:26:39.872Z",
    updated_at: "2020-11-06T02:30:45.527Z",
  },
  {
    _id: "5ee1f91f6160b9004b713868",
    id: "5ee1f91f6160b9004b713867",
    title: "Rancher Labsとパートナシップ",
    category: "リリース",
    content:
      '<p><a href="http://www.dreamnews.jp/press/0000158937/">プレスリリース:フォーシーズンズ、Rancher Labsとパートナシップ契約を締結</a></p>',
    thumbnail:
      "https://officialwebstorage.blob.core.windows.net/images/13596379787023305-partner-logos_199.png",
    display_date: "2017-08-23T09:26:00.000Z",
    created_at: "2020-06-11T09:27:59.542Z",
    updated_at: "2020-06-11T09:48:38.334Z",
  },
  {
    _id: "5ee1f9436160b9004b71386a",
    id: "5ee1f9436160b9004b713869",
    title: " 情報セキュリティ基本方針について",
    category: "お知らせ",
    content:
      "<p>情報セキュリティ基本方針を、2017年8月1日（火）に制定しました。</p>",
    display_date: "2017-08-02T09:28:00.000Z",
    created_at: "2020-06-11T09:28:35.266Z",
    updated_at: "2020-06-11T09:49:16.215Z",
  },
  {
    _id: "60224c6faa9a2a002dbfaaa4",
    id: "60224c6faa9a2a002dbfaaa3",
    title: "2022年採用活動",
    category: "お知らせ",
    content:
      '<p>・2020年新卒生　求人情報を公開しました。</p>\n<p><a href="https://www.0004s.com/recruit" target="_blank">https://www.0004s.com/recruit</a></p>\n<p>・京都工芸繊維大学　オンライン・合同企業説明会に参加します。</p>\n<p>3月4日12:50～14:20</p>\n<p>・東商ジョブフェアに参加します。</p>\n<p>3月16日13:00～17:00@新宿エルタワーサンスカイルームA室</p>',
    display_date: "2021-02-09T08:47:00.000Z",
    created_at: "2021-02-09T08:48:47.951Z",
    updated_at: "2021-02-09T08:50:07.698Z",
  },
  {
    _id: "60224df7aa9a2a002dbfaaa6",
    id: "60224df7aa9a2a002dbfaaa5",
    title: "ISO27001（情報セキュリティマネジメントシステム）更新",
    category: "お知らせ",
    content:
      '<p>ISO27001（情報セキュリティ マネジメントシステム）認証を更新しました。<br><a href="https://www.0004s.com/pdf/ISMS_registration_certificate.pdf#zoom=100">登録証はこちらからご覧いただけます。</a></p>',
    display_date: "2021-02-09T04:53:00.000Z",
    created_at: "2021-02-09T08:55:19.261Z",
    updated_at: "2021-02-09T08:56:17.197Z",
  },
  {
    _id: "6045a60fb66012002d670482",
    id: "6045a60fb66012002d670481",
    title: "エンジニア転職フェア",
    category: "お知らせ",
    content:
      '<p>エンジニア転職フェアに出展します。</p>\n\t<p>\n\t<a href="https://type.jp/s/fair/e/?waad=cC26NDaq">https://type.jp/s/fair/e/?waad=cC26NDaq</a>\n\t</p>',
    thumbnail:
      "https://officialwebstorage.blob.core.windows.net/images/59404060994491-9fb11e48-a9a8-430c-8871-5e4f8bfeefc6.jpg",
    display_date: "2021-03-08",
    created_at: "2021-03-08T04:20:31.968Z",
    updated_at: "2021-04-01T07:48:11.954Z",
  },
  {
    _id: "123",
    id: "123",
    title: "今後のお知らせについて",
    category: "お知らせ",
    content:
      '<p>採用サイトのリニューアルに伴い、お知らせにつきましては採用サイトを利用いたします。</p>\n\t<p>\n\t<a target="_blank" href="https://recruit.0004s.com/">https://recruit.0004s.com/</a>\n\t</p>',
    display_date: "2023-12-11",
    created_at: "2023-12-11T04:20:31.968Z",
    updated_at: "2023-12-11T07:48:11.954Z",
  },
];
